import { Box, Button, Chip, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import axios from "axios"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { CartContext } from "../Context/CartContext"

const CheckoutIndex = props => {
    const { clearCart, cart } = useContext(CartContext);
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const confirm = useConfirm()

    const collectionInput = useRef("")
    const nameInput = useRef("")
    const phoneInput = useRef("")
    const emailInput = useRef("")
    const staffInput = useRef("")
    const noteInput = useRef("")
    const staffConfirmInput = useRef("")

    function submitForm(e){
        e.preventDefault()
        confirm({ content: <Box mt={5} sx={{textAlign:'center'}}><Typography variant="body1">The team member will take your depost and finalise your order.</Typography></Box>, title:"PLEASE PASS THE DEVICE BACK TO THE TEAM MEMBER TO COMPLETE THE ORDER", allowClose: false, dialogProps:{maxWidth:"xl"}, contentProps:{style:{height:'50%'}}, titleProps:{textAlign: "center"} })
        .then(() => {
            confirm({ content: <FormControl fullWidth>
                <TextField label="What is your name?" required defaultValue="" inputRef={staffConfirmInput} variant="filled" fullWidth inputProps={{autoComplete: 'disabled'}} />
            </FormControl>, title:<><div>TEAM MEMBER</div><br /><div>PLEASE TAKE THE $10 DEPOSIT AND SIGN YOUR NAME BELOW TO CONFIRM ORDER</div></>, allowClose: false, dialogProps:{maxWidth:"xl"}, titleProps:{textAlign: "center"} })
            .then(() => {
                const data = {
                    collection: collectionInput.current.value,
                    name: nameInput.current.value,
                    phone: phoneInput.current.value,
                    email: emailInput.current.value,
                    staff: staffInput.current.value,
                    staffconfirm: staffConfirmInput.current.value,
                    storeid: "2997",
                    cart: cart,
                    notes: noteInput.current.value
                };
                async function sendData(){
                    await axios.post("https://retailbs.com.au/seasonal/add_order.php", data).then(res=>{
                        if(res.data === 1){
                            enqueueSnackbar('Order has been placed successfully!', { variant: 'success'});
                            clearCart()
                            setTimeout(() => {
                                navigate("/")
                            }, 2000);
                            return true
                        } else {
                            enqueueSnackbar('An error occured placing the order, please try again!', { variant: 'error'});
                            return false
                        }
                    }).catch(res=>{
                        enqueueSnackbar('An error occured placing the order, please try again1!', { variant: 'error'});
                        return false
                    })
                }
                if(!sendData()){
                    return false
                }
                
            })
            .catch(() => {
                return false
            }
            );
        })
        .catch(() => {
            return false
        }
    );
    }

    return(<form id="checkout-form" onSubmit={(e)=>submitForm(e)}>
        <Grid container spacing={2} sx={{mt:2}}>
            <Grid item xs={12}>
                <Chip
                    label="Checkout"
                    sx={{
                        color: 'white',
                        fontSize: '1.5rem',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)',
                    }}
                />
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <FormControl fullWidth required>
                    <Typography variant="overline"><b>Please Select your collection date *</b></Typography>
                    <Select
                        defaultValue=""
                        inputRef={collectionInput}
                        required
                        name="collection"
                        variant="outlined"
                    >
                        <MenuItem value="2022-12-22">Thursday 22nd December</MenuItem>
                        <MenuItem value="2022-12-23">Friday 23rd December</MenuItem>
                        <MenuItem value="2022-12-24">Saturday 24th December</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <Typography variant="overline"><b>Full Name *</b></Typography>
                    <TextField required variant="outlined" fullWidth inputRef={nameInput} inputProps={{autoComplete: 'disabled'}} />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <Typography variant="overline"><b>Contact Number *</b></Typography>
                    <TextField type="phone" required variant="outlined" fullWidth inputRef={phoneInput} inputProps={{autoComplete: 'off'}} />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Typography variant="overline"><b>Email Address *</b></Typography>
                    <TextField type="email" required variant="outlined" fullWidth inputRef={emailInput} inputProps={{autoComplete: 'disabled'}} />
                    <FormHelperText>We won't send you any promotional material.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Typography variant="overline"><b>Any additional notes?</b></Typography>
                    <TextField variant="outlined" inputRef={noteInput} inputProps={{autoComplete: 'disabled'}} />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Typography variant="overline"><b>What is the name of the team member who assisted you? *</b></Typography>
                    <TextField required variant="outlined" inputRef={staffInput} inputProps={{autoComplete: 'disabled'}} />
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <FormControl fullWidth>
                    <Button type="submit" color="success" fullWidth variant="contained">Place Order</Button>
                </FormControl>
            </Grid>
        </Grid>
        </form>
    )
}

export default CheckoutIndex