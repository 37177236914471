import { useContext } from 'react';
import { Button, SwipeableDrawer, Grid, List, ListItem, Typography } from '@mui/material';
import { CartContext } from '../Context/CartContext';
import { SidebarContext } from '../Context/SidebarContext';
import { useConfirm } from 'material-ui-confirm';
import { Box } from '@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartItem from './CartItem';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const SideDrawer = props => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm()
    const { cart, clearCart, cartCount } = useContext(CartContext);
    const { isOpen, setIsOpen } = useContext(SidebarContext);

    function emptyCart(){
        confirm({ description: "This will empty your current cart, and cannot be undone." })
            .then(() => {
            clearCart()
            enqueueSnackbar('Your cart has been cleared successfully!', { variant: 'success'});
            setTimeout(() => {
                window.location.reload()
            }, 2000);
            })
            .catch(() => {
            return false
        });
    }

    let productList
    let totalPrice = 0

    if(cart.length === 0){
        productList = <ListItem><Grid item xs={12} sx={{textAlign: "center"}}><Typography color='default' variant="body1" sx={{width:'100%'}}><b>Your cart is empty!</b></Typography></Grid></ListItem>
    }

    if(cart.length > 0){
        productList = cart.map((product)=> {
            let priceCalc = product.price
            if(product.is_weigh === "1"){
                let weightCalc = product.weight
                priceCalc = ((parseFloat(weightCalc) * parseFloat(priceCalc)*parseInt(product.orderqty))).toFixed(2)
            } else {
                priceCalc = (parseFloat(priceCalc)*parseInt(product.orderqty)).toFixed(2)
            }
            totalPrice = (parseFloat(totalPrice) + parseFloat(priceCalc)).toFixed(2)
            return <CartItem key={product.id} product={product} />
        })
    }

    return (
        <SwipeableDrawer
            sx={{
                "& .MuiDrawer-paper": {
                    maxWidth: 380,
                    width: '100%'
                }
            }}
            anchor='right'
            open={isOpen}
            onOpen={()=>setIsOpen(true)}
            onClose={()=>setIsOpen(false)}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginX: 'auto',
                mt: 2,
                mb: 3
            }}>
                <ShoppingCartIcon fontSize='large' color='primary' />
                <Typography color='primary' variant="h5"><b>MY CART</b></Typography>
            </Box>
            <Grid container spacing={0}>
                <List sx={{width:'100%'}}>
                    {productList}
                </List>
                <Grid item xs={12} sx={{textAlign: 'right', mr: 2, mt:2}}>
                    <Typography variant='body1' component="div">*Estimated Total: <b>${totalPrice}</b></Typography>
                </Grid>
            </Grid>
            {(cartCount > 0) && <Button sx={{m:2, mt:3, mb: 1}} color="success" fullWidth={false} variant="contained" onClick={()=>{navigate("/checkout");setIsOpen(false)}}>Continue to Checkout</Button>}
            {(cartCount > 0) && <Button sx={{mx:2}} color="error" variant="contained" onClick={()=>emptyCart()}>Clear Cart</Button>}
            <Typography variant='caption' sx={{mt:3, mx: 1, textAlign: 'right'}} component="div">* Please note these are estimated prices only. Prices will be charged at market prices at the time of pickup</Typography>
        </SwipeableDrawer>
    )
}

export default SideDrawer