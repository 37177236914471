import { Chip, Fab, Grid, List, ListItem, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../Context/SidebarContext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductTile from './ProductTile';
import { CartContext } from '../Context/CartContext';
import axios from 'axios';
import { useSnackbar } from 'notistack';

function ShopIndex() {
    const { enqueueSnackbar } = useSnackbar()
    const { isOpen, setIsOpen } = useContext(SidebarContext);
    const { cartCount } = useContext(CartContext)
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
      axios.get("https://retailbs.com.au/seasonal/get_categories.php").then(res=>{
        setCategories(res.data)
        axios.get("https://retailbs.com.au/seasonal/get_products.php").then(res=>{
        setProducts(res.data)
      }).catch(err=>{
        enqueueSnackbar('An error occured loading products, please try again!', { variant: 'error'});
      })
      }).catch(err=>{
        enqueueSnackbar('An error occured loading categories, please try again!', { variant: 'error'});
      })
    }, [enqueueSnackbar])
    

    let categoryList
    let count

    if(categories.length > 0){
        categoryList = categories.map((val)=>{
            let productList = []
            if(products.length > 0){
                productList = products.filter(prod => prod.category === val.id);
                productList = productList.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                 }).map((res)=> {
                    return <ProductTile key={res.id} product={res} />
                })
            }
            if(productList.length === 0){
                productList = <ListItem><Typography variant='overline' component='div'>No items in this category</Typography></ListItem>
            }
            return <Grid key={val.name} item xs={12}>
                <Chip
                    id={val.link}
                    label={val.name}
                    sx={{
                        color: 'white',
                        fontSize: '1.5rem',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)',
                    }}
                />
                    <List>{productList}</List>
            </Grid>
        })
    }

    

    if(cartCount > 0){
        count = "("+cartCount+")"
    }

    return (<>
        <Grid container spacing={3}>
            {categoryList}
        </Grid>
        <Fab onClick={()=>setIsOpen(!isOpen)} sx={{position: "fixed", bottom: 20, right: 20}} variant="extended" color="primary">
            <ShoppingCartIcon sx={{ mr: 1 }} />
            Show Cart {count}
        </Fab>
    </>);
  }
  
  export default ShopIndex;
  