import { Container } from "@mui/system";
import { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import SideDrawer from './Cart/SideDrawer';
import { CartContext } from './Context/CartContext';
import { SidebarContext } from "./Context/SidebarContext";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from 'notistack';
import ShopIndex from "./Shop/Index";
import Header from "./Header";
import CheckoutIndex from "./Checkout/Index";


const router = createBrowserRouter([
  {
    path: "/",
    element: <>
    <Header />
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <ShopIndex />
    </Container>
    <SideDrawer />
  </>
  },
  {
    path: "/checkout",
    element: <>
              <Header />
              <Container maxWidth="xl" sx={{marginTop: '20px'}}>
                <CheckoutIndex />
              </Container>
              <SideDrawer />
            </>
  },
]);

const App = props => {
  const [cart, setCart] = useState((localStorage.getItem("seasonal-cart")) ? checkCart(JSON.parse(localStorage.getItem("seasonal-cart"))) : [])
  const [isOpen, setIsOpen] = useState(false)
  const [cartCount, setCartCount] = useState((localStorage.getItem("seasonal-cart")) ? checkCart(JSON.parse(localStorage.getItem("seasonal-cart"))).length : 0)

  function addToCart(product, qty){
    let newcart = cart
    product["orderqty"] = qty
    if(qty === "0"){
      newcart = newcart.filter(prod => {return prod.id !== product.id})
    } else {
      const i = newcart.findIndex(_element => _element.id === product.id);
      if (i > -1) newcart[i] = product;
      else newcart.push(product);
    }
    setCart(newcart)
    setCartCount(newcart.length)
    localStorage.setItem("seasonal-cart", JSON.stringify(cart))
    return true
  }

  function clearCart(){
    setCart([])
    setCartCount(0)
    localStorage.removeItem("seasonal-cart")    
  }

  function checkCart(c){
    return c.filter(prod => {return parseInt(prod.orderqty) !== 0})
  }

  return (
    
      <SidebarContext.Provider value={{isOpen, setIsOpen}}>
        <CartContext.Provider value={{cart, addToCart, cartCount, clearCart}}>
          <SnackbarProvider maxSnack={3}>
            <ConfirmProvider>
              <RouterProvider router={router} />
            </ConfirmProvider>
          </SnackbarProvider>
        </CartContext.Provider>
      </SidebarContext.Provider>
  );
}

export default App;
