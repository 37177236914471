import styled from "@emotion/styled";
import { Chip, Grid, ListItem, Typography } from "@mui/material"

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#fff",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#F0F0F0",
    },
}));

const ProductTile = props => {
    const { product } = props

    if(!product){
        return false
    }
    if(!product.id){
        return false
    }

    let priceCalc = product.price
    let weight = ""
    if(product.is_weigh === "1"){
        let weightCalc = product.weight
        priceCalc = ((parseFloat(weightCalc) * parseFloat(priceCalc)*parseInt(product.orderqty))).toFixed(2)
        if(parseFloat(weightCalc) < 1.00){
            weightCalc = (parseFloat(weightCalc) * 1000)*parseInt(product.orderqty)+"g"
        } else {
            weightCalc = weightCalc + "kg"
        }
        weight = <Chip sx={{whiteSpace: 'normal', textTransform: 'uppercase', fontSize:'.7rem'}} size="small" color="warning" label={"Approximately " + weightCalc} />
    } else {
        priceCalc = (parseFloat(priceCalc)*parseInt(product.orderqty)).toFixed(2)
    }

    return (<StyledListItem sx={{borderBottom: '1px solid #000', borderTop: '1px solid #000'}}>
        <Grid item xs={2}>
            <Typography variant="body1" sx={{textAlign: 'left', fontSize:'1.5rem'}}><b>{product.orderqty}</b> x </Typography>
        </Grid>
        <Grid item xs={10}>
            <Typography variant="body2" sx={{textTransform: 'uppercase'}}><b>{product.name}</b></Typography>
            <Typography variant="body1" component="div" sx={{textTransform: 'uppercase'}}><small>Estimated Price <b>${priceCalc}</b></small></Typography>
            {weight}
        </Grid>
    </StyledListItem>)
}

export default ProductTile