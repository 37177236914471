import styled from "@emotion/styled";
import { Chip, Grid, ListItem, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { CartContext } from '../Context/CartContext';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#fff",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#F0F0F0",
    },
}));

const ProductTile = props => {
    const { product } = props
    const { cart, addToCart } = useContext(CartContext);
    const { enqueueSnackbar } = useSnackbar()
    const [orderqty, setOrderQty] = useState(0)

    useEffect(() => {
      let currCart = cart
      currCart.map((item)=>{
        if(item.id === product.id){
            if(item.orderqty !== orderqty){
                setOrderQty(item.orderqty)
            }
        }
        return false
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, product.id])
    

    function addItem(product, qty){
        if(!addToCart(product, qty)){
            enqueueSnackbar("An error occuured adding " + product.name + " to your cart", { variant: 'error'});
        } else {
            if(qty === "0"){
                enqueueSnackbar(product.name + " has been removed from your cart", { variant: 'success'});
            } else {
                enqueueSnackbar(product.name + " has been added to your cart", { variant: 'success'});
            }
        }
    }

    if(!product){
        return false
    }
    if(!product.id){
        return false
    }

    let weight = ""
    let sign = "ea"
    if(product.is_weigh === "1"){
        let weightCalc = product.weight
        sign = "/kg"
        if(parseFloat(weightCalc) < 1.00){
            weightCalc = (parseFloat(weightCalc) * 1000) + "g"
        } else {
            weightCalc = weightCalc + "kg"
        }
        weight = <Chip sx={{whiteSpace: 'normal', textTransform: 'uppercase', fontWeight: 'bold'}} size="small" color="warning" label={"Each item is approximately " + weightCalc} />
    }
    return (<StyledListItem sx={{borderBottom: '1px solid #000', borderTop: '1px solid #000'}}>
        <Grid container spacing={3}>
        <Grid item xs={8}>
            <Typography variant="body1" component="div" sx={{textTransform: 'uppercase'}}><b>{product.name}</b></Typography>
            <Typography variant="caption" component="div" sx={{textTransform: 'uppercase'}}>{product.caption}</Typography>
            {weight}
        </Grid>
        <Grid item xs={2}>
        <Typography variant="body1" component="div" sx={{textTransform: 'uppercase', textAlign: 'center'}}><small>Est. Price<br /><b>${product.price}{sign}</b></small></Typography>
        </Grid>
        <Grid item xs={2}>
            <TextField
                variant="outlined"
                type="number"
                inputProps={
                    {
                        autoComplete: 'disabled',
                        name: "qty",
                        step: "1",
                        min: "0"
                    }
                }
                sx={
                    {
                        input: {
                            textAlign: "center",
                            backgroundColor: (parseInt(orderqty) > 0) && "rgba(0,255,0, 0.3) !important"
                        }
                    }
                }
                onFocus={
                    (e)=>e.target.select()
                }
                min="0"
                value={orderqty}
                onChange={(e)=>setOrderQty(e.target.value)}
                onInput={
                    (e)=>{e.target.value = Math.round(e.target.value)}
                }
                onBlur={
                    (e)=>addItem(product, e.target.value)
                } 
            />
        </Grid>
        </Grid>
    </StyledListItem>)
}

export default ProductTile