import { Alert, AppBar, Container, MenuItem, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

const Header = props => {
    const { enqueueSnackbar } = useSnackbar()
    const [categories, setCategories] = useState([])

    useEffect(() => {
      axios.get("https://retailbs.com.au/seasonal/get_categories.php").then(res=>{
        setCategories(res.data)
      }).catch(err=>{
        enqueueSnackbar('An error occured loading categories, please try again!', { variant: 'error'});
      })
    }, [enqueueSnackbar])

    let categoryList

    if(categories.length > 0){
        categoryList = categories.map((page)=>{
            return <MenuItem key={page.name}  sx={{'a:link, a:active, a:visited, a:hover':{color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize: {xs: '.5rem'}}}}>
                    <a href={"/#"+page.link}><Typography textAlign="center"  sx={{fontSize: {xs: '.65rem', sm: '1rem'}}}><strong>{page.name}</strong></Typography></a>
                    </MenuItem>
        })
    }

    return(
        <><AppBar position="static">
            <Toolbar disableGutters>
                <ShoppingCartIcon sx={{ mx: 1 }} />
                <Box sx={{ flexGrow: 1, display: { xs: 'flex'} }}>
                    {categoryList}
                </Box>
            </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{mt:3, textAlign: 'center'}}>
            <Alert severity="warning" icon={false} variant="filled" sx={{'& .MuiAlert-message':{textAlign:"center", width:"100%"}}}>Please Note: You will be required to pay a $10 deposit for any order placed. Prices will be charged at market prices at the time of pickup.</Alert>
        </Container>
        </>
    )
}

export default Header